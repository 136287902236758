import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Footer.module.css';
import Wrapper from '../UI/Wrapper/Wrapper';
import twitter from '../assets/brands/twitter.svg';
import instagram from '../assets/brands/instagram.svg';
import linkedin from '../assets/brands/linkedin.svg';
import facebook from '../assets/brands/facebook-f.svg';
import envelope from '../assets/icons/envelope.svg';
import twitterGold from '../assets/brands/twitter-gold.svg';
import instagramGold from '../assets/brands/instagram-gold.svg';
import linkedinGold from '../assets/brands/linkedin-gold.svg';
import facebookGold from '../assets/brands/facebook-gold.svg';
import envelopeGold from '../assets/icons/envelope-gold.svg';

const Footer: FunctionComponent = () => {
  const { pathname } = useLocation();
  return (
    <footer>
      <section
        className={`${styles.contact} ${
          pathname === '/spot' ? styles.bgDark : ''
        }`}
      >
        <Wrapper>
          <div className={styles.contactWrapper}>
            <div>
              <h3
                className={`${styles.contactHeader} ${
                  pathname === '/spot' ? styles.dark : ''
                }`}
              >
                NEWSLETTER SUBSCRIPTION
              </h3>
              <form className={styles.form}>
                <div className={styles.formElement}>
                  <input
                    className={styles.input}
                    type='text'
                    name='email'
                    placeholder='E-mail'
                  />
                  <button
                    type='button'
                    className={`${styles.button} ${
                      pathname === '/spot' ? styles.bgGolden : ''
                    }`}
                  >
                    OK
                  </button>
                </div>
                <div className={styles.formElement}>
                  <div className={styles.termsContainer}>
                    <input type='checkbox' name='terms' id='terms' />
                    <label
                      htmlFor='terms'
                      className={`${pathname === '/spot' ? styles.dark : ''}`}
                    >
                      Agree to the{' '}
                      <Link
                        className={`${styles.term} ${
                          pathname === '/spot' ? styles.gold : ''
                        }`}
                        to='/terms-conditions'
                      >
                        Terms and Conditions
                      </Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className={styles.contactContainer}>
              <div className={styles.contactField}>
                {/* <span>
                  <img
                    src={pathname === '/spot' ? numpadGold : numpad}
                    alt='numpad'
                  />
                </span> */}
                {/* <span
                  className={`${styles.info} ${
                    pathname === '/spot' ? styles.dark : ''
                  }`}
                >
                  +1 (725) 223 0569
                </span> */}
              </div>
              <div className={styles.contactField}>
                <span>
                  <img
                    src={pathname === '/spot' ? envelopeGold : envelope}
                    alt='mail'
                  />
                </span>
                <span
                  className={`${styles.info} ${
                    pathname === '/spot' ? styles.dark : ''
                  }`}
                >
                  info@orbvpn.com
                </span>
              </div>
              <div className={styles.social}>
                <a href='https://twitter.com/orbvpn'>
                  <img
                    src={pathname === '/spot' ? twitterGold : twitter}
                    alt='Brand Twitter Account link'
                  />
                </a>
                <a href='https://www.instagram.com/orbvpn/'>
                  <img
                    src={pathname === '/spot' ? instagramGold : instagram}
                    alt='Brand instagram Account link'
                  />
                </a>
                <a href='https://facebook.com/orbvpn'>
                  <img
                    src={pathname === '/spot' ? facebookGold : facebook}
                    alt='Brand facebook Account link'
                  />
                </a>
                <a href='httos://www.linkedin.com/company/orbvpn/'>
                  <img
                    src={pathname === '/spot' ? linkedinGold : linkedin}
                    alt='Brand linkedin Account link'
                  />
                </a>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
      <section
        className={`${styles.footer} ${
          pathname === '/spot' ? styles.bgDark : ''
        }`}
      >
        <Wrapper>
          <div className={styles.footerWrapper}>
            <div className={styles.policies}>
              {/* <a href='#' target='_blank' rel='noopener noreferrer'>
                Disclaimer
              </a> */}
              <Link to='/privacy-policy'>Privacy &amp; Policy</Link>
              <Link to='/terms-conditions'>Terms &amp; Conditions</Link>
            </div>
            <p className={styles.copyright}>
              Copyright &copy; 2023 NIMA OÜ
            </p>
          </div>
        </Wrapper>
      </section>
    </footer>
  );
};

export default Footer;
