import { useLocation } from 'react-router';
import styles from './Tagline.module.css';

const Tagline = () => {
  const { pathname } = useLocation();
  return (
    <p
      className={`${styles.tagline} ${pathname === '/spot' ? styles.dark : ''}`}
    >
      Intelligent, Connectivity &amp; Security
    </p>
  );
};

export default Tagline;
