import Wrapper from '../../components/UI/Wrapper/Wrapper';
import styles from './docs.module.css';

const TermsConditions = () => {
  return (
    <div>
      <Wrapper>
        <div>
          <div className={styles.screen}>
            <h4>Terms and conditions</h4>
            These terms and conditions (&quot;Agreement&quot;) sets forth the
            general terms and conditions of your use of the &quot;Orb Vpn&quot;
            website (“Website”) and applications (&quot;Apps&quot; or
            “Application”) and any of its related products and services
            (collectively, &quot;Services&quot;). This Agreement is legally
            binding between you (&quot;User&quot;, &quot;you&quot; or
            &quot;your&quot;) and ORB VPN (&quot;OrbVPN&quot;, &quot;we&quot;,
            &quot;us&quot; or &quot;our&quot;). By accessing and using the
            Website, Apps and Services, you acknowledge that you have read,
            understood, and agree to be bound by the terms of this Agreement. If
            you are entering into this Agreement on behalf of a business or
            other legal entity, you represent that you have the authority to
            bind such entity to this Agreement, in which case the terms
            &quot;User&quot;, &quot;you&quot; or &quot;your&quot; shall refer to
            such entity. If you do not have such authority, or if you do not
            agree with the terms of this Agreement, you must not accept this
            Agreement and may not access and use the Website, Apps and Services.
            You acknowledge that this Agreement is a contract between you and
            the OrbVPN, even though it is electronic and is not physically
            signed by you, and it governs your use of the Website, Apps and
            Services.
            <h4>Accounts and membership</h4>
            You must be at least 13 years of age to use the Apps and Services.
            By using the Apps and Services and by agreeing to this Agreement you
            warrant and represent that you are at least 13 years of age. If you
            create an account in the Application, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may, but have no
            obligation to, monitor and review new accounts before you may sign
            in and start using the Services. Providing false contact information
            of any kind may result in the termination of your account. You must
            immediately notify us of any unauthorized uses of your account or
            any other breaches of security. We will not be liable for any acts
            or omissions by you, including any damages of any kind incurred as a
            result of such acts or omissions. We may suspend, disable, or delete
            your account (or any part thereof) if we determine that you have
            violated any provision of this Agreement or that your conduct or
            content would tend to damage our reputation and goodwill. If we
            delete your account for the foregoing reasons, you may not
            re-register for our Services. We may block your email address and
            Internet protocol address to prevent further registration.
            <h4>Links to other resources</h4>
            Although the Apps and Services may link to other resources (such as
            websites, mobile applications, etc.), we are not, directly or
            indirectly, implying any approval, association, sponsorship,
            endorsement, or affiliation with any linked resource, unless
            specifically stated herein. We are not responsible for examining or
            evaluating, and we do not warrant the offerings of, any businesses
            or individuals or the content of their resources. We do not assume
            any responsibility or liability for the actions, products, services,
            and content of any other third parties. You should carefully review
            the legal statements and other conditions of use of any resource
            which you access through a link in the Apps and Services. Your
            linking to any other off-site resources is at your own risk.
            <h4>Prohibited uses</h4>
            In addition to other terms as set forth in the Agreement, you are
            prohibited from using the Apps and Services or its content: 
            <ol>
              <li>for any unlawful purpose; </li>
              <li>
                to solicit others to perform or participate in any unlawful
                acts; 
              </li>
              <li>
                to violate any international, federal, provincial or state
                regulations, rules, laws, or local ordinances; 
              </li>
              <li>
                to infringe upon or violate our intellectual property rights or
                the intellectual property rights of others; 
              </li>
              <li>
                to harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability; 
              </li>
              <li>to submit false or misleading information; </li>
              <li>
                use OrbVPN to mask your identity for illegal or malicious
                purposes;
              </li>

              <li>
                to upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Apps and Services, third party
                products and services, or the Internet; 
              </li>
              <li>
                to spam, phish, pharm, pretext, spider, crawl, or scrape; 
              </li>
              <li>for any obscene or immoral purpose; </li>
              <li>exploit or attempt to exploit our refund policy;</li>
              <li>
                to interfere with or circumvent the security features of the
                Apps and Services, third party products and services, or the
                Internet; 
              </li>
              <li>
                use any 3rd party software to interfere with or attempt to
                interfere with the Services;
              </li>
              <li>Otherwise infringe or circumvent these Terms.</li>
            </ol>
            We reserve the right to terminate your use of the Apps and Services
            for violating any of the prohibited uses.
            <h4>Intellectual property rights</h4>
            &quot;Intellectual Property Rights&quot; means all present and
            future rights conferred by statute, common law or equity in or in
            relation to any copyright and related rights, trademarks, designs,
            patents, inventions, goodwill and the right to sue for passing off,
            rights to inventions, rights to use, and all other intellectual
            property rights, in each case whether registered or unregistered and
            including all applications and rights to apply for and be granted,
            rights to claim priority from, such rights and all similar or
            equivalent rights or forms of protection and any other results of
            intellectual activity which subsist or will subsist now or in the
            future in any part of the world. This Agreement does not transfer to
            you any intellectual property owned by us or third parties, and all
            rights, titles, and interests in and to such property will remain
            (as between the parties) solely with us. All trademarks, service
            marks, graphics and logos used in connection with the Apps and
            Services, are trademarks or registered trademarks of the OrbVPN or
            its licensors. Other trademarks, service marks, graphics and logos
            used in connection with the Apps and Services may be the trademarks
            of other third parties. Your use of the Apps and Services grants you
            no right or license to reproduce or otherwise use any of our or
            third-party trademarks.
            <h4>Indemnification</h4>
            You agree to indemnify and hold us and our affiliates, directors,
            officers, employees, agents, suppliers and licensors harmless from
            and against any liabilities, losses, damages or costs, including
            reasonable attorneys&#39; fees, incurred in connection with or
            arising from any third party allegations, claims, actions, disputes,
            or demands asserted against any of them as a result of or relating
            to your information, your use of the Apps and Services or any
            willful misconduct on your part.
            <h4>Severability</h4>
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid or unenforceable. If any provision or
            portion of any provision of this Agreement shall be held to be
            illegal, invalid or unenforceable by a court of competent
            jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions thereof shall remain in full force and
            effect.
            <h4>Dispute resolution</h4>
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of Nevada, without regard to its rules on
            conflicts or choice of law and, to the extent applicable, the laws
            of United States. The exclusive jurisdiction and venue for actions
            related to the subject matter hereof shall be the courts located in
            Nevada, and you hereby submit to the personal jurisdiction of such
            courts. You hereby waive any right to a jury trial in any proceeding
            arising out of or related to this Agreement. The United Nations
            Convention on Contracts for the International Sale of Goods does not
            apply to this Agreement.
            <h4>Changes and amendments</h4>
            We reserve the right to modify this Agreement or its terms relating
            to the Apps and Services at any time, effective upon posting of an
            updated version of this Agreement in the Apps. When we do, we will
            send you an email to notify you. You understand that it is your
            responsibility to review any such modifications. If you continue to
            use OrbVPN Apps and Services after the modifications take effect,
            you understand and agree to the revised Terms.
            <h4>Payment and refund policy</h4>
            You can subscribe to OrbVPN Apps and Services on a service period
            basis. After signing up for OrbVPN Apps and Services you may choose
            a payment method. OrbVPN may, with prior notice, change the fees it
            charges you for accessing the Apps and Services at any time. All
            prices are quoted for an unmetered number of devices. You agree that
            all fees charged by OrbVPN for accessing the Apps and Services will
            be automatically charged to your payment account on file with
            OrbVPN, at the time of any automatic renewal of your subscription
            for Apps and Services.  Your satisfaction with our Apps and Services
            is our main goal. If you choose not to use our paid Apps and
            Services anymore, we guarantee you a right to claim a refund within
            30 days following your subscription of our Apps and Services.
            However, we would like to solve your issue before you decide to
            cancel the subscription, therefore you are always welcome to reach
            out to us at info@orbvpn.com.
            You have a right to cancel your account at any time, however,
            cancelled accounts will not be refunded for the unused part of the
            ongoing service period. No refunds will be considered for accounts
            terminated for violation of these Terms.
            <h4>Disclaimers and Limitations on Liability.</h4>
            OrbVPN provides its website and services to you on an “as is” and
            “as available” basis. OrbVPN cannot guarantee that the Apps and
            Services or its content is error free and makes no representations
            about the technical accuracy or functionality of the Apps and
            Services or that the content is accurate, error free or up to date.
            Actual service coverage, speeds, locations and quality may vary.
            OrbVPN makes no representations – your use of the Apps and Services
            is your sole responsibility. OrbVPN and its affiliates, shall not be
            liable and shall not have responsibility of any kind to any
            Subscriber or other individual for any loss or damage, regardless of
            the type of claim or the nature of the cause of action, even if
            advised of the possibility of such damages, that you incur in the
            event of:
            <ul>
              <li>any failure or interruption of the Apps and Services;</li>
              <li>
                any act or omission of any third party involved in making the
                Apps and Service or the data contained therein available to you;
              </li>
              <li>
                your access to or inability to access the Website or our
                Services, including for viruses alleged to have been obtained
                from the services;
              </li>
              <li>your interactions on the Apps and Service;</li>
              <li>
                any direct, indirect, special, consequential or punitive damages
                allegedly sustained arising out of your use of the Apps and
                Services;
              </li>
              <li>the sale or purchase of any goods or merchandise;</li>
              <li>your failure to comply with this Agreement;</li>
              <li>
                the cost of procurement of substitute goods or services; or
                unauthorized access to or alteration of your transmissions or
                data, whether or not the circumstances giving rise to such cause
                may have been within the control of OrbVPN or of any vendor
                providing software, services, or support for the Apps and
                Service.
              </li>
            </ul>
            You hereby agree to release service provider, its affiliates and
            third-party service providers, and each of their respective
            directors, officers, employees, and agents from claims, demands and
            damages (actual and consequential) of every kind and nature, known
            and unknown, suspected and unsuspected, disclosed and undisclosed
            (“claims”), arising out of or in any way connected with your use of
            this Apps or our Services.
            <h4>Acceptance of these terms</h4>
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By accessing and using the Apps and
            Services you agree to be bound by this Agreement. If you do not
            agree to abide by the terms of this Agreement, you are not
            authorized to access or use the Apps and Services.
            <h4>Contacting us</h4>
            If you would like to contact us to understand more about this
            Agreement or wish to contact us concerning any matter relating to
            it, you may send an email to
            <a href='https://orbvpn.com/contact/'>info@orbvpn.com</a>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default TermsConditions;
