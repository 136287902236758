import Wrapper from '../../components/UI/Wrapper/Wrapper';
import OrbVpnLogo from '../../components/assets/company/logo.svg';
import Rotation from '../../components//assets/company/Rotation.svg';
import Earth from '../../components//assets/company/Earth.svg';
import HowWeDoIt from '../../components//assets/company/HowWeDoIt.svg';
import Story from '../../components//assets/company/Story.svg';
import Story2007 from '../../components//assets/company/Story2007.svg';
import Story2009 from '../../components//assets/company/Story2009.svg';
import Story2012 from '../../components//assets/company/Story2012.svg';
import Story2013 from '../../components//assets/company/Story2013.svg';
import Story2020 from '../../components//assets/company/Story2020.svg';
import Mercury1 from '../../components//assets/company/Mercury1.svg';
import Mercury2 from '../../components//assets/company/Mercury2.svg';
import Mercury3 from '../../components//assets/company/Mercury3.svg';
import SunStar from '../../components//assets/company/SunStar.svg';
import NewStars from '../../components//assets/company/NewStars.svg';
import NewStarsMob from '../../components//assets/company/NewStarsMob.svg';

import styles from './CompanyScreens.module.css';

const CompanyScreens = () => {
  return (
    <>
      <section className={styles.section1}>
        <Wrapper>
          <div className={styles.part1}>
            <div className={styles.rotation}>
              <img src={Rotation} alt='Rotation' />
            </div>
            <div className={styles.topLogo}>
              <img src={OrbVpnLogo} alt='OrbVPN logo' />
            </div>
            <div className={styles.content}>
              <div className={styles.topTitle}>
                <h1>WE ARE DEDICATED TO BUILD A BETTER INTERNET</h1>
              </div>
              <div>
                <h2 className={styles.title}>ABOUT</h2>
                <p className={styles.aboutP}>
                  We are dedicated to build a better Internet. We build
                  applications that fuel open and free communication throughout
                  the world, with over 10,000 users worldwide. To achieve this,
                  OrbVPN has strategically partnered with experts and leaders,
                  utilizing their in-depth knowledge and expertise in each
                  field.
                </p>
              </div>
            </div>
            <div className={styles.earthbox}>
              <img src={Earth} alt='Earth' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.section2}>
        <Wrapper>
          <div className={styles.part2}>
            <div className={styles.left}>
              <img src={HowWeDoIt} alt='How we do it' />
            </div>
            <div className={styles.right}>
              <p>
                Since 2007, when the OrbVPN was established, it has
                progressively succeeded to develop frontier cybersecurity
                technology, trusted by over 10,000 consumers and businesses
                worldwide. Building on decade of development and UX/UI
                experience, our knowledgeable team brings variety of expertise
                to developing a truly innovative cybersecurity company. In
                OrbVPN, our team is devoted to improving day-by-day the security
                and the speed of our system.
              </p>
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.section3}>
        <Wrapper>
          <div className={styles.part3}>
            <h2 className={styles.title}>STORY</h2>
            <div className={styles.history}>
              <div className={styles.storyImg}>
                <img src={Story2007} alt='Story' className={styles.story2007} />
                <img src={Story2009} alt='Story' className={styles.story2009} />
                <img src={Story2012} alt='Story' className={styles.story2012} />
                <img src={Story2013} alt='Story' className={styles.story2013} />
                <img src={Story2020} alt='Story' className={styles.story2020} />
              </div>
              <div className={styles.bigImg}>
                <img src={Story} alt='Story' />
              </div>
              <div className={styles.storys}>
                <div className={styles.story}>
                  <h4 className={styles.year}>2007</h4>
                  <p className={styles.storyP}>
                    OrbVPN is funded and builds its infrastructure, services and
                    developed sales.
                  </p>
                </div>
                <div className={styles.story}>
                  <h4 className={styles.year}>2009</h4>
                  <p className={styles.storyP}>
                    OrbVPN managed to grow its sales and reach 20,000 users
                    worldwide.
                  </p>
                </div>
                <div className={styles.story}>
                  <h4 className={styles.year}>2012</h4>
                  <p className={styles.storyP}>
                    OrbVPN becomes part of Orb Group and expands its activities.
                  </p>
                </div>
                <div className={styles.story}>
                  <h4 className={styles.year}>2013</h4>
                  <p className={styles.storyP}>
                    OrbVPN releases its next generation protocols and
                    intelligent security softwares.
                  </p>
                </div>
                <div className={styles.story}>
                  <h4 className={styles.year}>2020</h4>
                  <p className={styles.storyP}>New version of OrbVPN.</p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.section4}>
        <Wrapper>
          <div className={styles.part4}>
            <h2 className={styles.title}>COMPANY CULTURE</h2>
            <div className={styles.company}>
              <div className={styles.culture}>
                <div className={styles.cultureTitle}>
                  <img src={Mercury1} alt='Freedom of information' />
                  <h3>Freedom of information</h3>
                </div>
                <p className={styles.cultureContent}>
                  At OrbVPN, our mission is to provide secure, private and
                  uncensored Internet access to anyone, anywhere. To do so, we
                  are pushing boundaries and developing the next generation of
                  cutting-edge cybersecurity products and solutions. We strongly
                  believe that Online Privacy is a Human Right, everybody
                  deserves to browse the web securely, and the Internet should
                  remain without borders.
                </p>
              </div>
              <div className={styles.culture}>
                <div className={styles.cultureTitle}>
                  <img src={Mercury2} alt='Trust Credibility' />
                  <h3>Trust & Credibility</h3>
                </div>
                <p className={styles.cultureContent}>
                  At OrbVPN, we pride ourselves to guarantee the privacy of our
                  users. We offer the most competitive price because our
                  ultimate goal is to provide universal online security, privacy
                  and access to content for every user globally.
                </p>
              </div>
              <div className={styles.culture}>
                <div className={styles.cultureTitle}>
                  <img
                    src={Mercury3}
                    alt='Together we contribute to a free and safer internet'
                  />
                  <h3>Together we contribute to a free and safer internet</h3>
                </div>
                <p className={styles.cultureContent}>
                  OrbVPN users play an essential role in building better
                  Internet security, privacy, and access platforms by constantly
                  sharing their feedback, contributing according to their means,
                  and raising awareness about online security and privacy
                  threats.
                </p>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.section5}>
        <Wrapper>
          <div className={styles.part5}>
            <h2 className={styles.title}>CAREERS</h2>
            <div className={styles.careers}>
              <h3 className={styles.carrersTitle}>Work with us!</h3>
              <p className={styles.carrersP}>
                Grow in your career and keep developing your skills with us by
                joining our team and become part of one of the best VPN
                services. Although we might not be recruiting when you apply, we
                will keep your information and contact you if a new position
                matching your interests pops up. If you have any questions, just
                send us an email to{' '}
                <a className={styles.mailto} href='mailto:info@orbvpn.com'>
                  info@orbvpn.com
                </a>
                .
              </p>
            </div>
            <div className={styles.sun}>
              <img src={SunStar} alt='Sun' />
            </div>
            <div className={styles.newStars}>
              <img src={NewStars} alt='' />
            </div>
            <div className={styles.newStarsMob}>
              <img src={NewStarsMob} alt='' />
            </div>
          </div>
        </Wrapper>
      </section>
    </>
  );
};

export default CompanyScreens;
