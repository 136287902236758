import React from 'react';
import ReactDOM from 'react-dom/client'; // Update the import for React 18
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Use createRoot to manage the root of your app
const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container); // Assert that container is not null

// Use the root.render method
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();