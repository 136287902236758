import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomeScreens from './screens/HomeScreens/HomeScreens';
import PriceScreens from './screens/PriceSreens/PriceScreens';
import TechnologyScreen from './screens/TechnologyScreen/TechnologyScreen';
import SpotScreens from './screens/SpotScreens/SpotScreens';
import CompanyScreens from './screens/CompanyScreens/CompanyScreens';
import Layout from './components/Layout/Layout';
import ScrollToTop from './hooks/ScrollToTop';
import Policies from './screens/Policies/Policies';
import TermsConditions from './screens/Policies/TermsConditions';

const App = () => {
  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomeScreens />} />
        <Route path='/pricing' element={<PriceScreens />} />
        <Route path='/technology' element={<TechnologyScreen />} />
        <Route path='/spot' element={<SpotScreens />} />
        <Route path='/company' element={<CompanyScreens />} />
        <Route path='/privacy-policy' element={<Policies />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Layout>
  );
};

export default App;
