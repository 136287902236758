import { FunctionComponent } from 'react';
import styles from './Backdrop.module.css';

interface IBackdrop {
  closeNavHandler: () => void;
}

const Backdrop: FunctionComponent<IBackdrop> = ({ closeNavHandler }) => {
  return (
    <div
      className={styles.backdrop}
      onTouchEnd={closeNavHandler}
      onClick={closeNavHandler}
    ></div>
  );
};

export default Backdrop;
