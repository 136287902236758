import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';
import Footer from '../Footer/Footer';
import Navigation from '../Navigation/Navigation';

interface LayoutProps {
  children?: ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <div style={pathname === '/spot' ? { backgroundColor: '#000' } : undefined}>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
