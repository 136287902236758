import { Link } from 'react-router-dom';
import styles from './GetStarted.module.css';

const GetStarted = () => {
  return (
    <div className={styles.btnBox}>
      <Link to='/pricing' className={styles.button}>
        Get Started
      </Link>
    </div>
  );
};

export default GetStarted;
