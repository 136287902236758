import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavigationItem.module.css';

interface INavigationItem {
  path: string;
  link: string;
  isButton?: boolean;
}

const NavigationItem: FunctionComponent<INavigationItem> = ({
  path,
  link,
  isButton = false,
}) => {
  const { pathname } = useLocation();
  return (
    <li className={styles.itemWrapper}>
      {path === '/panel/login' ? (
        <a
          href={path}
          className={`${styles.item} ${
            pathname === '/spot' && !isButton ? styles.dark : ''
          } ${isButton ? styles.button : ''}`}
        >
          {link}
        </a>
      ) : (
        <Link
          to={path}
          className={`${styles.item} ${
            pathname === '/spot' && !isButton ? styles.dark : ''
          } ${isButton ? styles.button : ''}`}
        >
          {link}
        </Link>
      )}
    </li>
  );
};

export default NavigationItem;
