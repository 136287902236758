import { FunctionComponent } from 'react';
import styles from './Row.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
export type Tfeature = {
  title: string;
  col1: string;
  col2: string;
  col3: string;
};

interface IRow {
  featureList: Tfeature[];
  isMobile?: boolean;
  group?: 'basic' | 'premium' | 'prefamily';
}

const Row: FunctionComponent<IRow> = ({
  featureList,
  isMobile = false,
  group,
}) => {
  if (isMobile) {
    return (
      <div className={`${styles.row}`}>
        {featureList.map((list, index) => (
          <ul key={index}>
            <li>
              <span>{list.title}</span>
            </li>
            {group === 'basic' ? (
              <>
                {list.col1 === 'check' ? (
                  <li>
                    <FontAwesomeIcon className={styles.check} icon={faCheck} />
                  </li>
                ) : list.col1 === 'cross' ? (
                  <li>
                    <FontAwesomeIcon className={styles.cross} icon={faTimes} />
                  </li>
                ) : (
                  <li>{list.col1}</li>
                )}
              </>
            ) : group === 'premium' ? (
              <>
                {list.col2 === 'check' ? (
                  <li>
                    <FontAwesomeIcon className={styles.check} icon={faCheck} />
                  </li>
                ) : list.col2 === 'cross' ? (
                  <li>
                    <FontAwesomeIcon className={styles.cross} icon={faTimes} />
                  </li>
                ) : (
                  <li>{list.col2}</li>
                )}
              </>
            ) : (
              <>
                {list.col3 === 'check' ? (
                  <li>
                    <FontAwesomeIcon className={styles.check} icon={faCheck} />
                  </li>
                ) : list.col3 === 'cross' ? (
                  <li>
                    <FontAwesomeIcon className={styles.cross} icon={faTimes} />
                  </li>
                ) : (
                  <li>{list.col3}</li>
                )}
              </>
            )}
          </ul>
        ))}
      </div>
    );
  }

  return (
    <div className={`${styles.row}`}>
      {featureList.map((list, index) => (
        <ul key={index}>
          <li>
            <span>{list.title}</span>
          </li>
          {list.col1 === 'check' ? (
            <li>
              <FontAwesomeIcon className={styles.check} icon={faCheck} />
            </li>
          ) : list.col1 === 'cross' ? (
            <li>
              <FontAwesomeIcon className={styles.cross} icon={faTimes} />
            </li>
          ) : (
            <li>{list.col1}</li>
          )}
          {list.col2 === 'check' ? (
            <li>
              <FontAwesomeIcon className={styles.check} icon={faCheck} />
            </li>
          ) : list.col2 === 'cross' ? (
            <li>
              <FontAwesomeIcon className={styles.cross} icon={faTimes} />
            </li>
          ) : (
            <li>{list.col2}</li>
          )}
          {list.col3 === 'check' ? (
            <li>
              <FontAwesomeIcon className={styles.check} icon={faCheck} />
            </li>
          ) : list.col3 === 'cross' ? (
            <li>
              <FontAwesomeIcon className={styles.cross} icon={faTimes} />
            </li>
          ) : (
            <li>{list.col3}</li>
          )}
        </ul>
      ))}
    </div>
  );
};

export default Row;
