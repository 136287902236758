import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Navigation.module.css';
import Backdrop from '../UI/Backdrop/Backdrop';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import NavigationItem from './NavigationItem/NavigationItem';
import Wrapper from '../UI/Wrapper/Wrapper';

import companylogo from '../assets/brands/ORB-VPN-Logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Tagline from '../UI/Tagline/Tagline';

const Navigation: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const closeNavHandler = () => setIsOpen(false);

  return (
    <nav
      className={`${styles.navbar} ${pathname === '/spot' ? styles.dark : ''}`}
    >
      <Wrapper>
        <div className={styles.navWrapper}>
          <div className={styles.logo}>
            <Link to='/'>
              <img src={companylogo} alt='Company Logo' />
            </Link>
          </div>
          <ul className={styles.nav}>
            <NavigationItem path='/pricing' link='Pricing' />
            <NavigationItem path='/technology' link='Technology' />
            <NavigationItem path='/spot' link='Spot' />
            <NavigationItem path='/company' link='Company' />
            {/* <NavigationItem path='/blog' link='Blog' /> */}
            <NavigationItem path='https://orbvpn.xyz' link='Login' />
            <NavigationItem path='/pricing' link='Get Started' isButton />
          </ul>
          <Tagline />

          <FontAwesomeIcon
            className={styles.hamburger}
            icon={faBars}
            color={pathname === '/spot' ? '#fff' : '#000'}
            onClick={closeNavHandler}
          />
        </div>
        {isOpen ? <Backdrop closeNavHandler={closeNavHandler} /> : null}

        <MobileNavigation isOpen={isOpen} />
      </Wrapper>
    </nav>
  );
};

export default Navigation;
