import styles from "./OrbVpnText.module.css";
import Military from "../../assets/spot/Military.svg";
import Save from "../../assets/spot/Save.svg";
import Parental from "../../assets/spot/Parental.svg";
import Virus from "../../assets/spot/Virus.svg";
import Hide from "../../assets/spot/Hide.svg";
import Bypass from "../../assets/spot/Bypass.svg";
import Browse from "../../assets/spot/Browse.svg";
import Unrestricted from "../../assets/spot/Unrestricted.svg";

const OrbVpnText = () => {
  return (
    <div className={styles.orbvpntext}>
      <div className={styles.textrow}>
        <div className={styles.textcol}>
          <img src={Military} alt="Military" />
          <p>Military grade encryption</p>
        </div>
        <div className={styles.textcol}>
          <img src={Save} alt="Save" />
          <p>Save money by virtual locations</p>
        </div>
      </div>
      <div className={styles.textrow}>
        <div className={styles.textcol}>
          <img src={Parental} alt="Parental" />
          <p>Parental controls</p>
        </div>
        <div className={styles.textcol}>
          <img src={Virus} alt="Virus" />
          <p>Virus, malware & botnet protection</p>
        </div>
      </div>
      <div className={styles.textrow}>
        <div className={styles.textcol}>
          <img src={Hide} alt="Hide" />
          <p>Hide the IP address</p>
        </div>
        <div className={styles.textcol}>
          <img src={Bypass} alt="Bypass" />
          <p>Bypass geo-restrictions</p>
        </div>
      </div>
      <div className={styles.textrow}>
        <div className={styles.textcol}>
          <img src={Browse} alt="Browse" />
          <p>Browse anonymously</p>
        </div>
        <div className={styles.textcol}>
          <img src={Unrestricted} alt="Unrestricted" />
          <p>Unrestricted access to websites</p>
        </div>
      </div>
    </div>
  );
};

export default OrbVpnText;
