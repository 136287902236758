import Wrapper from '../../components/UI/Wrapper/Wrapper';
import styles from './docs.module.css';

const Policies = () => {
  return (
    <div>
      <Wrapper>
        <div>
          <div className={styles.screen}>
            <h4>Privacy policy</h4>
            At OrbVPN, we stand behind our commitment to user privacy and
            security. When you use our OrbVPN Apps and Services, you’ll share
            some information with us, which we use to provide and improve those
            Apps and Services, send notifications and updates, troubleshoot, and
            to support our free product through advertising. Our goal is to be
            upfront about what we collect, how we use it, and whom we share it
            with, and therefore, this privacy policy (&quot;Policy&quot;)
            describes how the personally identifiable information
            (&quot;Personal Information&quot;) you may provide in the
            &quot;OrbVPN&quot; Application (&quot;Apps&quot; or
            &quot;Application&quot;) and any of its related products and
            services (collectively, &quot;Services&quot;) is collected,
            protected and used. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update this information. OrbVPN will never collect,
            store, log, or share any browsing activity, your true IP address,
            including no logging of browsing history, traffic destination, data
            content, or DNS queries, and we always delete your true IP address
            after your VPN session is closed. Since we only collect the minimal
            usage statistics needed to maintain the quality of our service, and
            do not store users’ personal information, we cannot, willingly or
            unwillingly, share user information with third-parties or government
            authorities - because this data does not exist. We never associate
            your email, true IP address, username, or unique mobile ID with your
            browsing information or online activities when you are using OrbVPN.
            Creating an account is always optional – we never require you to
            register or otherwise provide us any personal information to
            download or use our products. We only use your information to
            provide you with our Apps and Services that we are constantly
            working to improve. This Policy is a legally binding agreement
            between you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;)
            and OrbVPN (&quot;OrbVPN&quot;, &quot;we&quot;, &quot;us&quot; or
            &quot;our&quot;). By accessing and using the product and Services,
            you acknowledge that you have read, understood, and agree to be
            bound by the terms of this Agreement. This Policy does not apply to
            the practices of companies that we do not own or control, or to
            individuals that we do not employ or manage.
            <h4>Automatic collection of information</h4>
            When you use the Application, our servers automatically record
            information that your device sends. This data may include
            information such as your device&#39;s IP address and location,
            device name and version, operating system type and version, language
            preferences, information you search for in the Application, access
            times and dates, information about your hardware and software, from
            device sensors, about your wireless and mobile network connections,
            , Information Collected by Cookies and Other Technologies and log
            information. Information collected automatically is used only to
            identify potential cases of abuse and establish statistical
            information regarding the usage of the Apps and Services. This
            statistical information is not otherwise aggregated in such a way
            that would identify any particular user of the system.
            <h4>Collection of personal information</h4>
            OrbVPN online collects some personal information from registered
            users. During the registration’s users have to input a username,
            password, and a valid email address. OrbVPN uses your email address
            for the following reasons:
            <ul>
              <li>To send emails related to payment transactions.</li>
              <li>
                To provide links to our website, including password reset
                emails.
              </li>
              <li>To send you updates and announcements.</li>
              <li>
                To communicate with you about your VPN services or respond to
                your communications.
              </li>
              <li>
                To send marketing information, such as OrbVPN offers, surveys,
                invitations, and content about other matters in connection with
                OrbVPN (“Marketing emails”). You can unsubscribe from the
                marketing and promotional materials at any time using the
                corresponding links in the emails.
              </li>
            </ul>
            You can access and use our Apps and Services without telling us who
            you are or revealing any information by which someone could identify
            you as a specific, identifiable individual. If, however, you wish to
            use some of the features in the Application, you may be asked to
            provide certain Personal Information (for example, your name and
            e-mail address). We may also receive a confirmation when you open an
            email from us. When purchasing Services, you will be required to
            provide additional information that may include a credit card number
            or other banking or payment information (&quot;Billing
            Information&quot;). OrbVPN app online has no access to your database
            password or any data stored in your local database on your devices.
            If you are using OrbVPN online data records or tasks synchronization
            and backup service, the OrbVPN online will have access to the
            encrypted binary file on our servers which contains your database.
            We need this access to provide you a secure backup and restore of
            your local personal database in case you have lost or damaged your
            device. OrbVPN online will have no access to the data stored in this
            database. We receive and store any information you knowingly provide
            to us when you fill any online forms in the Application. When
            required, this information may include the following:
            <ul>
              <li>Personal details such as name, country of residence, etc.</li>
              <li>Contact information such as email address, address, etc.</li>
              <li>
                Account details such as username, unique user ID, password, etc.
              </li>
              <li>Geolocation data such as latitude and longitude.</li>
              <li>
                Information about other individuals such as your family members,
                friends, etc.
              </li>
              <li>
                Any other materials you willingly submit to us such as articles,
                images, feedback, etc.
              </li>
              Some of the information we collect is directly from you via the
              Apps and Services. However, we may also collect Personal
              Information about you from other sources such as public databases
              and our joint marketing partners. You can choose not to provide us
              with your Personal Information, but then you may not be able to
              take advantage of some of the features in the Application. Users
              who are uncertain about what information is mandatory are welcome
              to contact us.
            </ul>
            Other than as disclosed in this Privacy Policy, OrbVPN Application
            online at no time will disclose identifiable personal data, if any,
            to any third parties without your express, written approval.
            <h4>Use and processing of collected information</h4>
            In order to make our Apps and Services available to you, or to meet
            a legal obligation, we need to collect and use certain Personal
            Information. If you do not provide the information that we request,
            we may not be able to provide you with the requested products or
            services. Any of the information we collect from you may be used for
            the following purposes:
            <ul>
              <li>Request user feedback</li>
              <li>Improve user experience</li>
              <li>Enforce terms and conditions and policies</li>
              <li>Protect from abuse and malicious users</li>
              <li>Respond to legal requests and prevent harm</li>
              <li>Run and operate the Apps and Services</li>
            </ul>
            Processing your Personal Information depends on how you interact
            with the Apps and Services, where you are located in the world and
            if one of the following applies: (i) you have given your consent for
            one or more specific purposes; this, however, does not apply,
            whenever the processing of Personal Information is subject to
            California Consumer Privacy Act or GDPR ; (ii) provision of
            information is necessary for the performance of an agreement with
            you and/or for any pre-contractual obligations thereof; (iii)
            processing is necessary for compliance with a legal obligation to
            which you are subject; (iv) processing is related to a task that is
            carried out in the public interest or in the exercise of official
            authority vested in us; (v) processing is necessary for the purposes
            of the legitimate interests pursued by us or by a third party. Note
            that under some legislations we may be allowed to process
            information until you object to such processing (by opting out),
            without having to rely on consent or any other of the following
            legal bases below. In any case, we will be happy to clarify the
            specific legal basis that applies to the processing, and in
            particular whether the provision of Personal Information is a
            statutory or contractual requirement, or a requirement necessary to
            enter into a contract. We may also obtain information from our
            affiliates and third parties. Here are a few examples: Advertisers
            may let us know you installed a new app or game so we can determine
            whether we showed you an ad for it. If you interact with one of our
            advertisers, they may share information with us to help target or
            measure the performance of ads. You can learn more about these types
            of ads in our Support Center.
            <h4>Managing information</h4>
            You are able to delete certain Personal Information we have about
            you. The Personal Information you can delete may change as the Apps
            and Services change. When you delete Personal Information, however,
            we may maintain a copy of the unrevised Personal Information in our
            records for the duration necessary to comply with our obligations to
            our affiliates and partners, and for the purposes described below.
            If you would like to delete your Personal Information or permanently
            delete your account, you can do so by contacting us. What do we do
            with the information we collect? For the detailed answer, go here.
            The short answer is: Provide you with an amazing set of products and
            services that we relentlessly improve. Here are the ways we do that:
            <ul>
              <li>
                Develop, operate, improve, deliver, maintain, and protect our
                products and services.
              </li>
              <li>
                Send you communications, including by email. For example, we may
                use email to respond to support inquiries or to share
                information about our products, services, and promotional offers
                that we think may interest you.
              </li>
              <li>Monitor and analyze trends and usage.</li>
              <li>
                Personalize our services by, among other things, suggesting
                friends or profile information, or customizing the content we
                show you, including ads.
              </li>
              <li>
                Contextualize your experience by, among other things, tagging
                your Memories content using your precise location information
                (if, of course, you’ve given us permission to collect that
                information) and applying other labels based on the content.
              </li>
              <li>
                Provide and improve ad targeting and measurement, including
                through the use of your precise location information (again, if
                you’ve given us permission to collect that information), both on
                and off our services.
              </li>
              <li>Enhance the safety and security of our Apps and Services.</li>
              <li>
                Verify your identity and prevent fraud or other unauthorized or
                illegal activity.
              </li>
              <li>
                Use information we’ve collected from cookies and other
                technology to enhance our services and your experience with
                them.
              </li>
              <li>Enforce our Terms of Use and other usage policies.</li>
            </ul>
            <h4>Control over your information</h4>
            We want you to be in control of your information, so we provide you
            with the following tools. Because your privacy is important to us,
            we may ask you to verify your identity or provide additional
            information before we let you access or update your personal
            information. We may also reject your request to access or update
            your personal information for a number of reasons, including, for
            example, that the request risks the privacy of other users or is
            unlawful. Revoking permissions. If you let us use your information,
            you can always change your mind and simply revoke your permission by
            changing the settings on your device if your device offers those
            options. Of course, if you do that, certain services may lose full
            functionality. Advertising Preferences. We try to show you ads that
            we think will be relevant to your interests. If you would like to
            modify the information, we and our advertising partners use to
            select these ads, you can do so in the app. Communicating with other
            users. It’s important to us that you stay in control over whom you
            communicate with.
            <h4>Disclosure of Information with Third Parties</h4>
            Depending on the requested Services or as necessary to complete any
            transaction or provide any service you have requested, we may share
            your information with your consent with our trusted third parties
            that work with us, any other affiliates and subsidiaries we rely
            upon to assist in the operation of the Application and Services
            available to you. We do not share Personal Information with
            unaffiliated third parties. For example, if another user allows us
            to collect information from their device phonebook—and you’re one of
            that user’s contacts—we may combine the information we collect from
            that user’s phonebook with other information we have collected about
            you. We may also obtain information from our affiliates and third
            parties. Here are a few examples: Advertisers may let us know you
            installed a new app or game so we can determine whether we showed
            you an ad for it. If you interact with one of our advertisers, they
            may share information with us to help target or measure the
            performance of ads. You can learn more about these types of ads in
            our Support Center. We will disclose any Personal Information we
            collect, use or receive if required or permitted by law, such as to
            comply with a subpoena, or similar legal process, and when we
            believe in good faith that disclosure is necessary to protect our
            rights, protect your safety or the safety of others, investigate
            fraud, or respond to a government request. You acknowledge and agree
            that we are not responsible for how those third parties collect or
            use your information. As always, we encourage you to review the
            privacy policies of every third-party service that you visit or use,
            including those third parties you interact with through our
            services. To learn more about third- party services in OrbVPN
            Online, visit our Support Site.
            <h4>Retention of information</h4>
            We will retain and use your Personal Information for the period
            necessary to comply with our legal obligations, resolve disputes,
            and enforce our agreements unless a longer retention period is
            required or permitted by law. We may use any aggregated data derived
            from or incorporating your Personal Information after you update or
            delete it, but not in a manner that would identify you personally.
            Once the retention period expires, Personal Information shall be
            deleted. Therefore, the right to access, the right to erasure, the
            right to rectification and the right to data portability cannot be
            enforced after the expiration of the retention period.
            <h4>Transfer of information</h4>
            We may collect your personal information from, transfer it to, and
            store and process it in the United States and other countries
            outside of where you live. Whenever we share information of EU users
            outside the EU we make sure an adequate transfer mechanism is in
            place
            <h4>The rights of users</h4>
            You may exercise certain rights regarding your information processed
            by us. In particular, you have the right to do the following: (i)
            you have the right to withdraw consent where you have previously
            given your consent to the processing of your information; (ii) you
            have the right to object to the processing of your information if
            the processing is carried out on a legal basis other than consent;
            (iii) you have the right to learn if information is being processed
            by us, obtain disclosure regarding certain aspects of the processing
            and obtain a copy of the information undergoing processing; (iv) you
            have the right to verify the accuracy of your information and ask
            for it to be updated or corrected; (v) you have the right, under
            certain circumstances, to restrict the processing of your
            information, in which case, we will not process your information for
            any purpose other than storing it; (vi) you have the right, under
            certain circumstances, to obtain the erasure of your Personal
            Information from us; (vii) you have the right to receive your
            information in a structured, commonly used and machine readable
            format and, if technically feasible, to have it transmitted to
            another controller without any hindrance. This provision is
            applicable provided that your information is processed by automated
            means and that the processing is based on your consent, on a
            contract which you are part of or on pre-contractual obligations
            thereof.
            <h4>The right to object to processing</h4>
            You have the right to object to our use of your information. With
            many types of data, we’ve provided you with the ability to simply
            delete it if you don’t want us processing it anymore. For other
            types of data, we’ve given you the ability to stop the use of your
            data by disabling the feature altogether. You can do these things in
            the app. If there are other types of information you don’t agree
            with us processing, you can contact us.
            <h4>Data protection rights under GDPR</h4>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights and we aim to take reasonable steps
            to allow you to correct, amend, delete, or limit the use of your
            Personal Information. If you wish to be informed what Personal
            Information, we hold about you and if you want it to be removed from
            our systems, please contact us. In certain circumstances, you have
            the following data protection rights:
            <ul>
              <li>
                You have the right to request access to your Personal
                Information that we store and have the ability to access your
                Personal Information.
              </li>
              <li>
                You have the right to request that we correct any Personal
                Information you believe is inaccurate. You also have the right
                to request us to complete the Personal Information you believe
                is incomplete.
              </li>
              <li>
                You have the right to request the erase your Personal
                Information under certain conditions of this Policy.
              </li>
              <li>
                You have the right to object to our processing of your Personal
                Information.
              </li>
              <li>
                You have the right to seek restrictions on the processing of
                your Personal Information. When you restrict the processing of
                your Personal Information, we may store it but will not process
                it further.
              </li>
              <li>
                You have the right to be provided with a copy of the information
                we have on you in a structured, machine-readable and commonly
                used format.
              </li>

              <li>
                You also have the right to withdraw your consent at any time
                where we relied on your consent to process your Personal
                Information.
              </li>
            </ul>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Information. For more
            information, please contact your local data protection authority in
            the European Economic Area (EEA).
            <h4>California privacy rights</h4>
            In addition to the rights as explained in this Policy, California
            residents who provide Personal Information (as defined in the
            statute) to obtain products or services for personal, family, or
            household use are entitled to request and obtain from us, once a
            calendar year, information about the Personal Information we shared,
            if any, with other businesses for marketing uses. If applicable,
            this information would include the categories of Personal
            Information and the names and addresses of those businesses with
            which we shared such personal information for the immediately prior
            calendar year (e.g., requests made in the current year will receive
            information about the prior year). To obtain this information please
            contact us.
            <h4>How to exercise these rights</h4>
            Any requests to exercise your rights can be directed to us through
            the contact details provided in this document. Please note that we
            may ask you to verify your identity before responding to such
            requests. Your request must provide sufficient information that
            allows us to verify that you are the person you are claiming to be
            or that you are the authorized representative of such person. You
            must include sufficient details to allow us to properly understand
            the request and respond to it. We cannot respond to your request or
            provide you with Personal Information unless we first verify your
            identity or authority to make such a request and confirm that the
            Personal Information relates to you.
            <h4>Privacy of children</h4>
            Our Apps and Services are not intended for—and we don’t direct them
            to—anyone under 13. And that’s why we do not knowingly collect
            personal information from anyone under 13. In addition, we may limit
            how we collect, use, and store some of the information of EU users
            between 13 and 16. In some cases, this means we will be unable to
            provide certain functionality to these users. If we need to rely on
            consent as a legal basis for processing your information and your
            country requires consent from a parent, we may require your parent’s
            consent before we collect and use that information. In accordance
            with the Federal Children&#39;s Online Privacy Protection Act of
            1998 (COPPA), OrbVPN online does not intentionally collect nor
            solicit personally identifiable information from children under the
            age of thirteen (13) years of age.
            <h4>Links to other resources</h4>
            The Apps and Services contain links to other resources that are not
            owned or controlled by us. Please be aware that we are not
            responsible for the privacy practices of such other resources or
            third parties. We encourage you to be aware when you leave the Apps
            and Services and to read the privacy statements of each and every
            resource that may collect Personal Information.
            <h4>Information security</h4>
            We secure information you provide on computer servers in a
            controlled, secure environment, protected from unauthorized access,
            use, or disclosure. We maintain reasonable administrative,
            technical, and physical safeguards in an effort to protect against
            unauthorized access, use, modification, and disclosure of Personal
            Information in its control and custody. However, no data
            transmission over the Internet or wireless network can be
            guaranteed. Therefore, while we strive to protect your Personal
            Information, you acknowledge that (i) there are security and privacy
            limitations of the Internet which are beyond our control; (ii) the
            security, integrity, and privacy of any and all information and data
            exchanged between you and the Apps and Services cannot be
            guaranteed; and (iii) any such information and data may be viewed or
            tampered with in transit by a third party, despite best efforts.
            <h4>Data breach</h4>
            In the event we become aware that the security of the Apps and
            Services has been compromised or users Personal Information has been
            disclosed to unrelated third parties as a result of external
            activity, including, but not limited to, security attacks or fraud,
            we reserve the right to take reasonably appropriate measures,
            including, but not limited to, investigation and reporting, as well
            as notification to and cooperation with law enforcement authorities.
            In the event of a data breach, we will make reasonable efforts to
            notify affected individuals if we believe that there is a reasonable
            risk of harm to the user as a result of the breach or if notice is
            otherwise required by law. When we do, we will send you an email,
            get in touch with you over the phone.
            <h4>Changes and amendments</h4>
            We reserve the right to modify this Policy or its terms relating to
            the Apps and Services from time to time in our discretion and will
            notify you of any material changes to the way in which we treat
            Personal Information. When we do, we will post a notification in the
            Application. We may also provide notice to you in other ways in our
            discretion, such as through contact information you have provided.
            Any updated version of this Policy will be effective immediately
            upon the posting of the revised Policy unless otherwise specified.
            Your continued use of the Apps and Services after the effective date
            of the revised Policy (or such other act specified at that time)
            will constitute your consent to those changes. However, we will not,
            without your consent, use your Personal Information in a manner
            materially different than what was stated at the time your Personal
            Information was collected.
            <h4>Acceptance of this policy</h4>
            You acknowledge that you have read this Policy and agree to all its
            terms and conditions. By accessing and using the Apps and Services
            you agree to be bound by this Policy. If you do not agree to abide
            by the terms of this Policy, you are not authorized to access or use
            the Apps and Services.
            <h4>Contacting us</h4>
            If you would like to contact us to understand more about this Policy
            or wish to contact us concerning any matter relating to individual
            rights and your Personal Information, you may send an email to
            <a href='https://orbvpn.com/contact/'>info@orbvpn.com</a>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Policies;
