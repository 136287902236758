import { useState } from 'react';
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import OrbVpnText from '../../components/Spot/OrbVpnText/OrbVpnText';
import OrbSpotText from '../../components/Spot/OrbSpotText/OrbSpotText';
import styles from './SpotScreen.module.css';
import modem from '../../components/assets/spot/modem.png';
import smmodem from '../../components/assets/spot/sm-modem.png';
import OrbTelly from '../../components/assets/spot/OrbTelly.svg';
import OrbRouter from '../../components/assets/spot/OrbRouter.svg';
import OrbFi from '../../components/assets/spot/OrbFi.svg';
import OrbDNS from '../../components/assets/spot/OrbDNS.svg';
import OrbCrowd from '../../components/assets/spot/OrbCrowd.svg';
import OrbPorter from '../../components/assets/spot/OrbPorter.svg';
import OrbMining from '../../components/assets/spot/OrbMining.svg';
import OrbVPN from '../../components/assets/spot/OrbVPN.svg';
import { brandDesc } from '../../components/constants/index';

const SpotScreens = () => {
  const [brandIndex, setBrandIndex] = useState<number>(0);

  return (
    <>
      <section className={styles.showcase}>
        <Wrapper>
          <div className={styles.showcaseWrapper}>
            <div className={styles.showcaseContent}>
              <h1 className={styles.contentHeader}>OrbSpot</h1>
              <h2 className={styles.contentHeader}>
                The Smartest Way of Modern Modem/Router
              </h2>
              <p className={styles.plead}>
                The Router That Gives Entertainment and Security Efficiently All
                In One.
              </p>
              <picture className={styles.showcaseImg}>
                <source srcSet={smmodem} media='(max-width: 768px)' />
                <img src={modem} alt='Modem/Router' />
              </picture>
              <button className={styles.btn}>Coming Soon</button>
            </div>
          </div>
        </Wrapper>
      </section>

      {/* Icons Features */}
      <section className={styles.section}>
        <Wrapper>
          <div className={styles.innerWidth}>
            {/* Icon Row */}
            <div className={styles.iconsrow}>
              <div className={styles.icon} onClick={() => setBrandIndex(0)}>
                <button>
                  <img
                    className={`${brandIndex === 0 ? styles.imgWhite : null}`}
                    src={OrbTelly}
                    alt='OrbTelly Logo'
                  />
                  <span>OrbTelly</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(1)}>
                <button>
                  <img
                    className={`${brandIndex === 1 ? styles.imgWhite : null}`}
                    src={OrbRouter}
                    alt='OrbRouter Logo'
                  />
                  <span>OrbRouter</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(2)}>
                <button>
                  <img
                    className={`${brandIndex === 2 ? styles.imgWhite : null}`}
                    src={OrbFi}
                    alt='OrbFi Logo'
                  />
                  <span>OrbFi</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(3)}>
                <button>
                  <img
                    className={`${brandIndex === 3 ? styles.imgWhite : null}`}
                    src={OrbDNS}
                    alt='OrbDNS Logo'
                  />
                  <span>OrbDNS</span>
                </button>
              </div>
            </div>
            {/* Text */}
            <div className={styles.brandDescWrapper}>
              <p className={styles.brandDesc}>{brandDesc[brandIndex].desc}</p>
            </div>
            {/* Icon Row */}
            <div className={styles.iconsrow}>
              <div className={styles.icon} onClick={() => setBrandIndex(4)}>
                <button>
                  <img
                    className={`${brandIndex === 4 ? styles.imgWhite : null}`}
                    src={OrbCrowd}
                    alt='OrbCrowd Logo'
                  />
                  <span>OrbCrowd</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(5)}>
                <button>
                  <img
                    className={`${brandIndex === 5 ? styles.imgWhite : null}`}
                    src={OrbPorter}
                    alt='OrbPorter Logo'
                  />
                  <span>OrbPorter</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(6)}>
                <button>
                  <img
                    className={`${brandIndex === 6 ? styles.imgWhite : null}`}
                    src={OrbMining}
                    alt='OrbMining Logo'
                  />
                  <span>OrbMining</span>
                </button>
              </div>
              <div className={styles.icon} onClick={() => setBrandIndex(7)}>
                <button>
                  <img
                    className={`${brandIndex === 7 ? styles.imgWhite : null}`}
                    src={OrbVPN}
                    alt='OrbVPN Logo'
                  />
                  <span>OrbVPN</span>
                </button>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>

      {/* OrbVPN Features */}
      <section className={styles.section}>
        <Wrapper>
          <div className={styles.innerWidth}>
            <h3 className={styles.orbpartheader}>OrbVPN Features</h3>
            <OrbVpnText />
          </div>
        </Wrapper>
      </section>

      {/* OrbSpot Specification */}
      <section>
        <Wrapper>
          <div className={styles.innerWidth}>
            <h3 className={styles.orbpartheader}>OrbSpot Specification</h3>
            <OrbSpotText />
            <div className={styles.spotbutton}>
              <button className={styles.btn}>Coming Soon</button>
            </div>
          </div>
        </Wrapper>
      </section>
    </>
  );
};

export default SpotScreens;
