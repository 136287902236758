import { FunctionComponent } from "react";
import starfull from "../../assets/icons/starfull.svg";
import styles from "./Rate.module.css";

type TRate = {
  rate: number;
};

const Rate: FunctionComponent<TRate> = ({ rate }) => {
  return (
    <div className={styles.rate}>
      {rate >= 1 ? <img src={starfull} alt="" /> : null}
      {rate >= 2 ? <img src={starfull} alt="" /> : null}
      {rate >= 3 ? <img src={starfull} alt="" /> : null}
      {rate >= 4 ? <img src={starfull} alt="" /> : null}
      {rate >= 5 ? <img src={starfull} alt="" /> : null}
    </div>
  );
};

export default Rate;
