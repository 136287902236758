import React from "react";
import styles from "./Wrapper.module.css";

interface IWrapper {
  children: React.ReactNode;
  overflowHidden?: boolean;
}

const Wrapper: React.FunctionComponent<IWrapper> = ({
  children,
  overflowHidden = true,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${
        overflowHidden ? styles.overflowHidden : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Wrapper;
