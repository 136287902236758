import { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/UI/Wrapper/Wrapper';
import styles from './HomeScreen.module.css';
import amazonLogo from '../../components/assets/brands/amazon_logo.svg';
import amazonprime from '../../components/assets/brands/amazonprime.svg';
import appletv from '../../components/assets/brands/appletv.svg';
import disney from '../../components/assets/brands/disney.svg';
import hbo from '../../components/assets/brands/hbo.svg';
import netflix from '../../components/assets/brands/netflix.svg';
import youtube from '../../components/assets/brands/youtube.svg';
import sun from '../../components/assets/sun.svg';
import buildbg from '../../components/assets/buildimg.svg';
import landbg from '../../components/assets/landingrocket.svg';
import landbg2 from '../../components/assets/landbgmobile.svg';
import twobg from '../../components/assets/twobgd.svg';
import twobgm from '../../components/assets/twobgm.svg';
import savebg from '../../components/assets/savebg.svg';
import savebg2 from '../../components/assets/savebg2.svg';
import showcasebottom from '../../components/assets/showcasebottom.svg';
import showcasebottom2 from '../../components/assets/showcasebottom-mobile.svg';
import happyend from '../../components/assets/happyend.svg';
import hifriend from '../../components/assets/hifriend.svg';
import friend from '../../components/assets/friend.svg';
import connectbg from '../../components/assets/connectbg.svg';
import connectbg2 from '../../components/assets/connectbg2.svg';
import connectgirl from '../../components/assets/connectgirl.svg';
import connectman from '../../components/assets/connectman.svg';
import connectphone from '../../components/assets/connectphone.svg';
import staticip from '../../components/assets/icons/staticip.svg';
import virusfilter from '../../components/assets/icons/virusfilter.svg';
import multilogin from '../../components/assets/icons/multilogin.svg';
import bandwidth from '../../components/assets/icons/bandwidth.svg';
import autosecure from '../../components/assets/icons/autosecure.svg';
import autoconnect from '../../components/assets/icons/autoconnect.svg';
import worldend from '../../components/assets/worldend.svg';
import worldend2 from '../../components/assets/worldend2.svg';
import encryption from '../../components/assets/icons/encryption.svg';
import googleplay from '../../components/assets/icons/googleplay.svg';
import applesbadge from '../../components/assets/icons/applesbadge.svg';
import macbadge from '../../components/assets/icons/macbadge.svg';
import microsoftbadge from '../../components/assets/icons/microsoftbadge.svg';
import Testimonial from '../../components/UI/Testimonial/Testimonial';
import GetStarted from '../../components/UI/GetStartedBtn/GetStarted';

import usaflag from '../../components/assets/country/USA.svg';
import latvia from '../../components/assets/country/latvia.svg';
import italy from '../../components/assets/country/italy.svg';

const HomeScreens = () => {
  const [vWidth, setVWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setVWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <section className={styles.showcase}>
        <Wrapper>
          <GetStarted />
          <div className={styles.sun}>
            <img src={sun} alt='Sun Smiling' />
          </div>

          <div className={styles.showcaseWrapper}>
            <div className={styles.showcaseContent}>
              <h1 className={styles.contentHeader}>
                Fast &amp; Secure VPN hosted by
                <span>
                  <img
                    src={amazonLogo}
                    className={styles.amazon}
                    alt='Amazon Company Logo'
                  />
                </span>
              </h1>
              <p className={styles.plead}>
                Protect Your Crypto Investments now
              </p>
              <div className={styles.action}>
                <small className={styles.actionL}>
                  Just from 1.75$ per month
                </small>
                <Link to='/pricing' className={styles.actionBtn}>
                  Get OrbVPN
                </Link>
                <small className={styles.actionS}>
                  7 day money-back guarantee
                </small>
              </div>
            </div>
          </div>
          <div className={styles.showcaseBottom}>
            <img
              src={vWidth > 768 ? showcasebottom : showcasebottom2}
              alt='something happened'
            />
          </div>
        </Wrapper>
      </section>
      <section className={styles.featureSection}>
        <Wrapper>
          <div className={styles.featureWrapper}>
            <div className={styles.feature}>
              <img src={autosecure} alt='Automatic Security feature' />
              <p>Automatic Security</p>
            </div>
            <div className={styles.feature}>
              <img src={staticip} alt='Static Ip feature' />
              <p>Static Ip</p>
            </div>
            <div className={styles.feature}>
              <img src={virusfilter} alt='Virus Filter feature' />
              <p>Virus Filtering</p>
            </div>
            <div className={styles.feature}>
              <img src={multilogin} alt='Multi Logins Feature' />
              <p>Multi Logins</p>
            </div>
            <div className={styles.feature}>
              <img src={encryption} alt='FIPS Encryption Feature' />
              <p>FIPS Encryption</p>
            </div>
            <div className={styles.feature}>
              <img src={bandwidth} alt='Unlimited Bandwidth Feature' />
              <p>Unlimited Bandwidth</p>
            </div>
            <div className={styles.feature}>
              <img src={autoconnect} alt='Auto Connection Feature' />
              <p>Auto Connection</p>
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd}>
        <Wrapper>
          <div className={styles.worldEndWrapper}>
            <div className={styles.worldEndContent}>
              <GetStarted />
              <h2 className={`${styles.contentHeader} ${styles.white}`}>
                Protect your online activity
              </h2>
              <p className={styles.worldendp}>
                Enhance the security of your identity, health, finance, family
                data when searching, browsing, shopping, using dating apps, and
                communicating.
              </p>
              <p className={styles.worldendp}>
                We encrypt all your data and keep no records of your individual
                activities online.
              </p>
              <p className={styles.worldendp}>
                You could instantly enjoy our sleek and seamless apps for
                Windows, Mac, iOS and Android.
              </p>
              <p className={styles.worldendp}>
                We’ve designed our apps with you in mind for a flawless
                experience.
              </p>
              <div className={styles.badges}>
                <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                  <img src={googleplay} alt='Download from google play' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                  <img src={applesbadge} alt='Download from apple store' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                  <img src={macbadge} alt='Download for your Mac' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.orb_vpn&hl=en_US&gl=US&pli=1'>
                  <img src={microsoftbadge} alt='Download for your windows' />
                </a>
              </div>
            </div>
            <div className={styles.worldEndSvg}>
              <img
                className={styles.worldendSVG}
                src={vWidth > 768 ? worldend : worldend2}
                alt="if you don't buy orbvpn, the world will end!"
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.topMenSvg}>
            <img className={styles.hifriend} src={friend} alt='My friend' />
          </div>
          <div className={styles.worldEndWrapper}>
            <div className={styles.worldEndContent}>
              <GetStarted />
              <h2 className={`${styles.contentHeader} ${styles.white}`}>
                High quality streaming experience
              </h2>
              <p className={styles.worldendp}>
                Say goodbye to buffering delays and enjoy a high-quality
                streaming experience.
              </p>
              <p className={styles.worldendp}>
                With OrbVPN, you can browse and explore content from across the
                globe, enhancing your online privacy and security while
                maintaining a smooth connection, regardless of your location.
              </p>
              <div className={styles.badges2}>
                <img src={netflix} alt='Netflix' />
                <img src={youtube} alt='Youtube' />
                <img src={appletv} alt='Apple tv' />
              </div>
              <div className={styles.badges2}>
                <img src={amazonprime} alt='Amazon Prime' />
                <img src={disney} alt='Disney' />
                <img src={hbo} alt='HBO' />
              </div>
            </div>
            <div className={styles.hifriendSVG}>
              <img src={hifriend} alt='Friends can be meet!' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.connect}>
        <Wrapper>
          <div className={styles.connectWrapper}>
            <div className={styles.boxes}>
              <div className={styles.connectBox}>
                <GetStarted />
                <div className={styles.boximg}>
                  <img src={connectgirl} alt='girl connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>World with no borders</h3>
                  <p className={styles.contentpf}>
                    Secure access to free and open internet.
                  </p>
                  <p className={styles.contentp}>
                    Escape form the big brother and experience the untargeted
                    social networks, news, and streaming wherever you are.
                  </p>
                </div>
              </div>
              <div className={styles.connectBox}>
                <div className={styles.boximg}>
                  <img src={connectphone} alt='phone connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>
                    Audited VPN technology
                  </h3>
                  <p className={styles.contentpf}>
                    We incorporate the #1 and up to date technologies.
                  </p>
                  <p className={styles.contentp}>
                    Our SSL protocol is integrated by 70% of the world’s largest
                    organizations and has passed security audits.
                  </p>
                </div>
              </div>
              <div className={styles.connectBox}>
                <div className={styles.boximg}>
                  <img src={connectman} alt='man connecting' />
                </div>
                <div className={styles.boxcontent}>
                  <h3 className={styles.contentTitle}>Protect your privacy</h3>
                  <p className={styles.contentpf}>
                    Secure access to free and open internet.
                  </p>
                  <p className={styles.contentp}>
                    Escape form the big brother and experience the untargeted
                    social networks, news, and streaming wherever you are.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.connectbg}>
              <img
                src={vWidth > 768 ? connectbg : connectbg2}
                alt='background img'
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.build}>
        <Wrapper>
          <div className={styles.buildWrapper}>
            <div className={styles.buildContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                OrbCrowd decentralized access to supercomputers (Comming Soon)
              </h2>
              <p className={styles.buildp}>
                Our AI Powered Intelligent and efficient resource management
                algorithm allowing you and others to share the hardware.
              </p>
              <p className={styles.buildp}>
                You will be rewarded with Orb token by selling computing power
                to corporates and individuals.
              </p>
            </div>
            <div className={styles.dummy}></div>
            <div className={styles.buildSvg}>
              <img src={buildbg} alt='Friends can be meet!' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.landing}>
        <Wrapper>
          <div className={styles.landingWrapper}>
            <div className={styles.landingContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                Blazing Speed &amp; Superior Security
              </h2>
              <p className={styles.worldendp}>
                Browse with lightning fast connection speeds and military grade
                intelligence agencies level encryption wherever you are
              </p>
              <p className={styles.worldendp}>
                Connect to OrbVPN's global, high-speed servers hosted by
                Microsoft Azure and surf the web free of throttling, download
                caps or bandwidth limits
              </p>
              <p className={styles.worldendp}>
                We manage our own private VPN network and write our own code to
                provide you with the fastest, most secure experience possible.
              </p>
            </div>

            <div className={styles.landingbg}>
              <img src={landbg} alt='landing background' />
            </div>
          </div>
        </Wrapper>
        <div className={styles.landingbgmobile}>
          <img src={landbg2} alt='landing background' />
        </div>
      </section>
      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.landingWrapper}>
            <div className={styles.cryptoContent}>
              <GetStarted />
              <h2 className={styles.contentHeader}>
                Protect your crypto investment
              </h2>
              <p className={styles.worldendp}>
                OrbVPN adds an extra shelter of security in the transaction
                process, protecting your cryptocurrencies investments from
                hackers.
              </p>
              <p className={styles.worldendp}>
                OrbVPN allows you to access the geo-restricted crypto exchanges
                and trading without borders
              </p>
            </div>
            <div className={styles.twobg}>
              <img src={twobg} alt='landing background' />
            </div>
            <div className={styles.twobgm}>
              <img src={twobgm} alt='landing background' />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.worldEnd3}>
        <Wrapper>
          <div className={styles.testimonialWrapper}>
            <GetStarted />
            <div className={styles.testimonials}>
              <Testimonial
                flag={latvia}
                rate={5}
                user={'LíVA P., LATVIA'}
                content={
                  'OrbVPN provides secure and private access to a free and open internet. Enabling access to social networks, sports, audio and video streaming, news, dating, gaming wherever you are, no mattr how restricted is your connection'
                }
              />
              <Testimonial
                flag={usaflag}
                rate={5}
                user={'MEHRAD G., USA'}
                content={
                  'As an American-Iranian visiting Iran, there is a lot i have to get used to, but restricted internet access isn’t one of them. Using OrbVPN, I have access to all social media including, twitter and telegram at any time.'
                }
              />
              <Testimonial
                flag={italy}
                rate={5}
                user={'SARA B., ITALY'}
                content={
                  'I’ve read that it’s easy for people to see your passwords and other sensitive data when on an open WiFi. I always use OrbVPN to protect my information and hide my IP and data.'
                }
              />
              <Testimonial
                flag={latvia}
                rate={5}
                user={'LíVA P., LATVIA'}
                content={
                  'OrbVPN provides secure and private access to a free and open internet. Enabling access to social networks, sports, audio and video streaming, news, dating, gaming wherever you are, no mattr how restricted is your connection'
                }
              />
            </div>
            <div className={styles.savebg}>
              <img
                src={vWidth > 700 ? savebg : savebg2}
                alt='landing background'
              />
            </div>
          </div>
        </Wrapper>
      </section>
      <section className={styles.happyend}>
        <Wrapper>
          <GetStarted />
          <div className={styles.sun}>
            <img src={sun} alt='Sun Smiling' />
          </div>
          <div className={styles.happyendWrapper}>
            <div className={styles.happyendContent}>
              <h1 className={styles.contentHeader}>
                Download the <span className={styles.brand}>OrbVPN</span> Now!
              </h1>
              <p className={styles.plead}>Join over 10,000 private users</p>
              <div className={styles.action}>
                <small className={styles.actionL}>
                  Enjoy absolute Internet Freedom, high grade of data protection
                  and online security around the world.
                </small>
                <Link to='/' className={styles.actionBtn}>
                  Get OrbVPN
                </Link>
                <small className={styles.actionS}>
                  We don't store or share your IP address
                </small>
              </div>
            </div>
          </div>
          <div className={styles.showcaseBottom}>
            <img src={happyend} alt='something happened' />
          </div>
        </Wrapper>
      </section>
    </>
  );
};

export default HomeScreens;
