import styles from './OrbSpotText.module.css';

const OrbVpnText = () => {
  return (
    <>
      <ul className={styles.wrapper}>
        <li className={styles.item}>
          <span>CPU</span>
          <span>Quad-core ARM Cortex-A73</span>
        </li>
        <li className={styles.item}>
          <span>Memory</span>
          <span>32GB DDR4</span>
        </li>
        <li className={styles.item}>
          <span>Storage</span>
          <span className={styles.wider}>128 GB</span>
        </li>
        <li className={styles.item}>
          <span>GPIO</span>
          <span>250</span>
        </li>
        <li className={styles.item}>
          <span>Wireless Speed</span>
          <span className={styles.wider}>Wi-Fi 6</span>
        </li>
        <li className={styles.item}>
          <span>Antenna</span>
          <span className={styles.wider}>On Board Antenna x2</span>
        </li>
        <li className={styles.item}>
          <span>Ethernet Speed</span>
          <span className={styles.wider}>1 Gbps</span>
        </li>
        <li className={styles.item}>
          <span>Power Consumption</span>
          <span className={styles.wider}>5700 mAh</span>
        </li>
        <li className={styles.item}>
          <span>Inputs/Outputs</span>
          <span className={styles.wider}>
            UCB-C HDMI (Upto 4K), and Ethernet
          </span>
        </li>
        <li className={styles.item}>
          <span>Support</span>
          <span className={styles.wider}>
            OrbVPN, OrbPorter, OrbDNS, Orb Router, OrbFi, Orb Telly, OrbCrowd
            and OrbMining
          </span>
        </li>
      </ul>
    </>
  );
};

export default OrbVpnText;
